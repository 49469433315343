<template>
    <vx-card title="Stock Transfer Order Approval">
        <div class="vx-input-group flex">
            <vs-tabs>
                <vs-tab label="Waiting Approval">
                    <tab-waiting-approval></tab-waiting-approval>
                </vs-tab>
                <vs-tab label="Approved">
                    <tab-approved></tab-approved>
                </vs-tab>
                <vs-tab label="Rejected">
                    <tab-rejected></tab-rejected>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>
<script>
import TabWaitingApproval from "./TabWaitingApproval.vue";
import TabApproved from "./TabApproved.vue";
import TabRejected from "./TabRejected.vue";
export default {
    components: {
        TabWaitingApproval,
        TabApproved,
        TabRejected,
    },
    data() {
        return {
            activeTab: "Waiting Approval",
        }
    },
    methods: {

    }
}
</script>